<template>
  <div class="policy width-100">
    <heard-vue></heard-vue>
    <!-- 主体区域 -->
    <div class="width-100 posi-rel min-help-height">
      <div class="policy-center space-between">
        <div class="policy-tab">
          <div
            class="width-100 tab-item flex-center pointer"
            v-for="(item, index) in helpList"
            :key="index"
            @click="tabId = index"
            :class="index == tabId ? 'tab-item-active' : ''"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="policy-text" v-if="helpList">
          <div class="policy-text-title width-100">{{ helpList[tabId].title }}</div>
          <div class="policy-richt width-100">
            <div v-html="helpList[tabId].content"></div>
          </div>
        </div>
      </div>
      <img src="../../assets/about-5.png" v-if="!emailShow" class="emain-img posi-abs pointer" alt="" @click="emailShow=true" />
    </div>
    <mail-vue v-if="emailShow" @hide="emailShow=false"></mail-vue>
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index"
export default {
  components: {
    heardVue,
    feetVue,
    mailVue,
  },

  data() {
    return {
      emailShow:false,
      tabId: 0,
      helpList:null
    };
  },

  created() {
    this.getPrivacy()
  },
  computed: {},
  mounted() {},

  methods: {
    getPrivacy(){
      api.getPrivacy().then(res=>{
        this.helpList=res.data
      })
    }
  },
};
</script>
<style lang="less" scoped>
.policy {
  background: #f8f8f8;
   .min-help-height{
    min-height: calc(100vh - 591px);
  }
  .policy-center {
    width: 1226px;
    margin: 0 auto;
    padding: 34px 0 90px 0;
    align-items: stretch;
    .policy-tab {
      width: 293px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .tab-item {
        height: 56px;
        font-size: 14px;
        color: #333333;
      }
      .tab-item-active {
        background: #fff5e6;
      }
    }
    .policy-text {
      width: 911px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .policy-text-title {
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        color: #333333;
        font-size: 16px;
      }
      .policy-richt {
        padding: 20px;
      }
    }
  }
}
.emain-img {
      width: 64px;
      height: 64px;
      left: 60px;
      bottom: 84px;
    }
</style>
